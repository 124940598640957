<template>
  <lf-card>
    <div
      class="flex flex-col items-center justify-center w-full h-full space-y-4 p-10"
    >
      <icon-base width="120" height="120" icon="empty-state" />
      <div class="flex flex-col space-y-4 items-center">
        <lf-h2 class="-mb-0">
          {{
            isClosing
              ? $t("OFFERS.CLOSING.NO_APPROVED_OFFERS")
              : $t("OFFERS.EMPTY_HEADING")
          }}
        </lf-h2>
        <span
          v-if="!isClosing"
          class="text-sm text-gray-500 font-normal whitespace-pre-line text-center w-102"
        >
          {{ $t("OFFERS.EMPTY_SUBHEADING") }}
        </span>
      </div>
      <create-self-funded-offer-button
        v-if="
          !isClosing &&
          !noProducts &&
          shouldShowCreateOfferButton &&
          canCreateOffer
        "
        :disabled="(isClient && !isUnderwritingEnabled) || isClientUser"
      />
    </div>
  </lf-card>
</template>

<script setup lang="ts">
import { useAuth } from "@/hooks/auth";
import CreateSelfFundedOfferButton from "./CreateSelfFundedOfferButton.vue";
import { useDeals } from "@/hooks/deals";
import { useActiveWorkflowTemplateStages } from "@/hooks/workflow";
import { computed } from "vue";
import { usePermissions } from "@/hooks/auth";
import { Ability, PermissionSubject } from "@/enums/auth";

defineProps<{
  isSelfFunding?: boolean;
  noProducts?: boolean;
  isClosing?: boolean;
}>();

const { canPerformActionReactive } = usePermissions();

const { hasPlacementStage } = useActiveWorkflowTemplateStages();

const { isFunder, isClient, isLendflowUser, isClientUser } = useAuth();
const { isClientFunded, isUnderwritingEnabled, activeDeal } = useDeals();

const canCreateOffer = canPerformActionReactive(
  PermissionSubject.offer,
  Ability.create,
  { application: activeDeal.value?.id }
);

const shouldShowCreateOfferButton = computed(() => {
  return (
    ((!isFunder || isClient) && isClientFunded.value) ||
    (!hasPlacementStage.value && isLendflowUser)
  );
});
</script>
